/**
 * @file
 * @copyright 2020
 * @author ThePotato97 (https://github.com/ThePotato97)
 * @license ISC
 */

.IconStack > .Icon {
  position: absolute;
  width: 100%;
  text-align: center;
}

.Spin {
  animation: spin infinite 5s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.IconStack {
  position: relative;
  display: inline-block;
  height: 1.2em;
  line-height: 2em;
  vertical-align: middle;

  &:after {
    color: transparent;
    content: '.';
  }
}

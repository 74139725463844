/* Typewriter effect 1 */
@keyframes typing {
  2%,
  46% {
    content: "";
  }
  4%,
  44% {
    content: "b";
  }
  6%,
  42% {
    content: "bl";
  }
  8%,
  40% {
    content: "blu";
  }
  10%,
  38% {
    content: "bluem";
  }
  12%,
  36% {
    content: "bluemoo";
  }
  14%,
  34% {
    content: "bluemoon";
  }
  16%,
  32% {
    content: "bluemoon p";
  }
  18%,
  30% {
    content: "bluemoon pid";
  }
  20%,
  28% {
    content: "bluemoon pido";
  }
  22%,
  26% {
    content: "bluemoon pidor";
  }

  48%,
  100% {
    content: "s";
  }
  50%,
  98% {
    content: "ss";
  }
  52%,
  97% {
    content: "ss2";
  }
  54%,
  96% {
    content: "ss22";
  }
  56%,
  94% {
    content: "ss220";
  }
  58%,
  92% {
    content: "ss220 ";
  }
  60%,
  90% {
    content: "ss220 e";
  }
  62%,
  88% {
    content: "ss220 en";
  }
  64%,
  86% {
    content: "ss220 enj";
  }
  66%,
  84% {
    content: "ss220 enjo";
  }
  68%,
  82% {
    content: "ss220 enjoy";
  }
  70%,
  80% {
    content: "ss220 enjoye";
  }
  72%,
  78% {
    content: "ss220 enjoyer";
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.typewriter {
  --caret: currentcolor;
}

.typewriter::before {
  content: "";
  animation: typing 5.5s infinite;
}

.typewriter::after {
  content: "";
  border-right: 1px solid var(--caret);
  animation: blink 0.5s linear infinite;
}

.typewriter.thick::after {
  border-right: 1ch solid var(--caret);
}

.typewriter.nocaret::after {
  border-right: 0;
}

@media (prefers-reduced-motion) {
  .typewriter::after {
    animation: none;
  }

  @keyframes sequencePopup {
    0%,
    100% {
      content: "developer";
    }
    25% {
      content: "writer";
    }
    50% {
      content: "reader";
    }
    75% {
      content: "human";
    }
  }

  .typewriter::before {
    content: "developer";
    animation: sequencePopup 12s linear infinite;
  }
}

/**/

#pBG {
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /*transition: background-position 1s ease-in;*/
}
.background {
  background: url("../../../public/imgs/12.png") repeat;
}
.stars {
  background: url("../../../public/imgs/13.png") repeat;
}
.anotherStars {
  background: url("../../../public/imgs/14.png") repeat;
}
.asteroids {
  background: url("../../../public/imgs/15.png") repeat;
  background-size: 35%;
}
.planets {
  background: url("../../../public/imgs/17.png") no-repeat center;
  background-size: 50%;
}
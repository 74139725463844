$mqIterations: 19;
@mixin fontResize($iterations) {
  $i: 1;
  @while $i <= $iterations {
    @media all and (min-width: 100px * $i) {
      .fit-text {
        font-size: 0.1em * $i;
      }
    }
    $i: $i + 1;
  }
}

@include fontResize($mqIterations);

@use '../colors.scss';

a {
  &:link,
  &:visited {
    color: colors.$blue;
  }
  &:hover,
  &:active {
    color: colors.$primary;
  }
}

@use '../colors.scss';

.SearchItem {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.SearchItem--box {
  background: black;
  border: thin solid #212121;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  position: relative;
  width: 3rem;
}

.SearchItem--amount {
  bottom: -4px;
  color: colors.$teal;
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  position: absolute;
  right: -6px;
}

.SearchItem--text {
  color: colors.$label;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  z-index: 1;
}
